import {forEach, isUndefined} from 'lodash'
import {z} from 'zod'
import type {ExtendedFuel} from '../../constants/fuels'
import {FUEL_ELEKTRO} from '../../constants/fuels'
import type {LegalForm} from '../../constants/legalFormTypes'
import {PRIVATE_PERSON} from '../../constants/legalFormTypes'
import type {VehicleType} from '../../constants/vehicleTypes'
import {VEHICLE_TYPE_SCOOTER} from '../../constants/vehicleTypes'
import {registrationDateIsValid} from '../utils'
import {validateBornNumber} from './validations'


export const bornNumberMatchesBirthday = (
  {legalForm, birthday, bornNumber}: {
    legalForm: LegalForm,
    birthday?: string,
    bornNumber?: string,
  },
  ctx: z.RefinementCtx,
) => {
  const bornNumberError = validateBornNumber(legalForm, birthday, bornNumber)
  if (bornNumberError) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['bornNumber'],
      message: bornNumberError,
    })
  }
  return z.NEVER
}

export const holderBornNumberMatchesBirthday = (
  {holderLegalForm, holderBirthday, holderBornNumber}: {
    holderLegalForm: LegalForm,
    holderBirthday?: string,
    holderBornNumber?: string
  },
  ctx: z.RefinementCtx,
) => {
  const bornNumberError = validateBornNumber(holderLegalForm, holderBirthday, holderBornNumber)
  if (bornNumberError) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['holderBornNumber'],
      message: bornNumberError,
    })
  }
  return z.NEVER
}


export const ownerBornNumberMatchesBirthday = (
  {ownerLegalForm, ownerBirthday, ownerBornNumber}: {
    ownerLegalForm: LegalForm,
    ownerBirthday?: string,
    ownerBornNumber?: string
  },
  ctx: z.RefinementCtx,
) => {
  const bornNumberError = validateBornNumber(ownerLegalForm, ownerBirthday, ownerBornNumber)
  if (bornNumberError) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['ownerBornNumber'],
      message: bornNumberError,
    })
  }
  return z.NEVER
}

export const volumeForNonElectroFuel = (
  {fuel, volume}: {
    fuel: ExtendedFuel,
    volume?: number,
  },
  ctx: z.RefinementCtx,
) => {
  if (fuel !== FUEL_ELEKTRO && !isUndefined(volume) && volume < 1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['volume'],
      message: 'Objem motora musí byť väčší ako 1.',
    })
  }
  return z.NEVER
}

export const licensePlateOrVin = (
  {vehicleType, licensePlate, vin}: {
    vehicleType: VehicleType,
    licensePlate?: string,
    vin?: string,
  },
  ctx: z.RefinementCtx,
) => {
  if (vehicleType !== VEHICLE_TYPE_SCOOTER && !licensePlate && !vin) {
    forEach(['licensePlate', 'vin'], (field) => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [field],
        message: 'Musí byť vyplnené ŠPZ alebo VIN',
      })
    })
  }
  return z.NEVER
}

export const conditionalOdometer = (
  {vehicleType, legalForm, odometer}: {
    vehicleType: VehicleType,
    legalForm: LegalForm,
    odometer?: number,
  },
  ctx: z.RefinementCtx,
) => {
  if (vehicleType !== VEHICLE_TYPE_SCOOTER && legalForm === PRIVATE_PERSON && !odometer) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['odometer'],
      message: 'Pole je povinné',
    })
  }
  return z.NEVER
}

export const checkRegistrationDate = (
  {productionYear, registrationDate}: {
    productionYear: number,
    registrationDate?: string,
  },
  ctx: z.RefinementCtx,
) => {
  if (registrationDate && !registrationDateIsValid(registrationDate, productionYear)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['registrationDate'],
      message: 'Rok registrácie nesmie byť nižší ako rok výroby vozdila.',
    })
  }
  return z.NEVER
}
