import {oneLine} from 'common-tags'
import {
  BRAND_NOT_FOUND_ERROR, MODEL_NOT_FOUND_ERROR, VALIDATION_ERROR, ZIP_CODE_CITY_PAIR_ERROR,
} from '../../../constants/errorCodes'
import t from '../translations'


type Error = {
  errorCode: string,
  details?: {
    vehicleType?: string,
    vehicleUsage?: string,
    fuel?: string,
  },
}

type Values = {
  vehicleType?: string,
  vehicleUsage?: string,
  brand?: string,
  model?: string,
  zipCode?: string,
  fuel?: string,
}

const getErrorMessage = (error: Error, values: Values) => {
  if (error.errorCode === VALIDATION_ERROR) {
    if (error.details?.vehicleType) return `${t(`form.${values.vehicleType}`)}`
    if (error.details?.fuel) return `${t('form.fuel')} "${t(`enums.${values.fuel}`)}"`
    if (error.details?.vehicleUsage) return `${t('form.vehicleWithUsage')} "${t(`enums.${values.vehicleUsage}`)}"`
    return 'zvolené parametre'
  }
  if (error.errorCode === BRAND_NOT_FOUND_ERROR) {
    return `značku ${values.brand}`
  }
  if (error.errorCode === MODEL_NOT_FOUND_ERROR) {
    return `model ${values.model}`
  }
  if (error.errorCode === ZIP_CODE_CITY_PAIR_ERROR) {
    return `PSČ ${values.zipCode}`
  }
}

export const formatErrorMessage = (error: Error, values: Values) => {
  if (!error) return t('form.errorGlobal')
  const errorMessage = getErrorMessage(error, values)
  if (errorMessage) {
    return oneLine`
      Momentálne nie je možné dojednať poistenie pre
      ${errorMessage}.
      Kontaktujte nás, prosím, mailom na
      <a href="mailto:info@ferovepzp.sk">info@ferovepzp.sk</a>.
      Ospravedlňujeme sa za výpadok.
    `
  }
  return t('form.errorGlobal')
}
